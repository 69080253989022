<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>خدمه العملاء</h3></div>

    <v-divider></v-divider>
    <v-container>
      <div>
        <v-row>
          <h4 class="blue--text">مركز خدمه العملاء</h4>
        </v-row>
        <v-row>
          <v-col cols="12" lg="8" sm="10" xs="10">
            <ul>
              <li>
                <h3>
                  تعتبر خدمة العملاء البوابة الاولى لدخول العميل لشركة مياه
                  الشرب والصرف الصحى بأسيوط الوادى الجديد حيث ان الهدف الاهم
                  لخدمة العملاء هو كسب رضا العميل وتحسين الصورة الذهنية عن
                  الشركة
                </h3>
              </li>
              <li>
                <h3 class="mt-3">
                  تعد الادارة العامة لخدمة العملاء من الادارات ذات اهمية كبرى
                  بشركة مياه الشرب والصرف الصحى بأسيوط والوادى الجديد حيث تقوم
                  من خلال فروعها المنتشرة بجميع مراكز المحافظة من تقديم العديد
                  من الخدمات ومنها التعاقد على عداد مياه جديد ( تنظيمى – كودى )
                  والتعاقد على توصيلة صرف صحى وتغير العدادات المعطلة والتنازل عن
                  ملكية العداد للغير وغسيل الخزانات وشحن عداد مسبق الدفع وتلقى
                  الشكاوى التجارية وفتعد هى الوجه المشرفة التى تقوم بحركة الوصل
                  المباشر مع العملاء
                </h3>
              </li>
              <!--  <v-img
              lazy-src="@/assets/Images/loading.gif"
              src="@/assets/Images/31.jpg"
              class="imgService"
            /> -->
            </ul>

            <h3 class="mt-10">
              ولكى تتمكن الشركـة من تقديم أفضل مستوى لخدمة العملاء قامت الشركــة
              بإنشاء مجموعة من مراكز خدمة العملاء فى مراكز مختلفة بحيث تقوم
              بتغطية أغلب المناطق الجغرافية للسكان داخل المحافظة ومراكزها.
            </h3>

            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel
                v-for="(item, i) in branchServices"
                :key="i"
                class="changeTitleStyle"
              >
                <v-expansion-panel-header
                  expand-icon="mdi-menu-down"
                  class="fade-in branchName"
                >
                  {{ item.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                  
                    <v-col xs="12" lg="6" md="6" sm="6" class="text-center">
                      <h4>
                        <v-icon color="green">phone</v-icon> {{ item.phone }}
                      </h4>
                      <h4>
                        <v-icon color="blue">mdi-fax</v-icon> {{ item.fax }}
                      </h4>
                      <h4 class="mt-5">
                        <a
                          :href="item.link"
                          class="indigo--text"
                          target="_blank"
                          >{{ item.address }}</a
                        >
                      </h4>
                    </v-col>
                      <v-col xs="12" lg="6" md="6" sm="6" v-if="item.imgSrc">
                      <v-img
                        lazy-src="@/assets/Images/loading.gif"
                        :src="item.imgSrc"
                        class="imgService"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" lg="4" sm="4" xs="4">
            <Link></Link>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import Link from "../../components/link.vue";
export default {
  components: { Link },
  data() {
    return {
      panel: [0],

      branchServices: [
        {
          Id: 4,
          name: " مركز خدمة عملاء الرئيسي  ",
          address: "أسيوط-امتداد شارع الجيش امام مول النصر",
          phone: "01278648276",
          fax: "088-2131662",
          imgSrc: require("@/assets/Images/31.jpg"),
          link: "https://www.google.com/maps/place/%D9%85%D8%B1%D9%83%D8%B2+%D8%AE%D8%AF%D9%85%D8%A7%D8%AA+%D8%B4%D8%B1%D9%83%D8%A9+%D9%85%D9%8A%D8%A7%D9%87+%D8%A7%D9%84%D8%B4%D8%B1%D8%A8+%D9%88%D8%A7%D9%84%D8%B5%D8%B1%D9%81+%D8%A7%D9%84%D8%B5%D8%AD%D9%89+%D8%A8%D8%A3%D8%B3%D9%8A%D9%88%D8%B7+%D9%88%D8%A7%D9%84%D9%88%D8%A7%D8%AF%D9%8A+%D8%A7%D9%84%D8%AC%D8%AF%D9%8A%D8%AF%E2%80%AD/@27.1876237,31.1797079,19.33z/data=!4m9!1m2!2m1!1z2LTYsdmD2Ycg2YXZitin2Ycg2KfYs9mK2YjYtyDYrtiv2YXZhyDYp9mE2LnZhdmE2KfYoQ!3m5!1s0x14450bbae33c7701:0xe15bbaf4f6a26605!8m2!3d27.1875106!4d31.1798922!15sCjTYtNix2YPZhyDZhdmK2KfZhyDYp9iz2YrZiNi3INiu2K_ZhdmHINin2YTYudmF2YTYp9ihkgEQY29ycG9yYXRlX29mZmljZQ",
        },
        {
          Id: 1,
          name: "مركز خدمة عملاء فرع ديروط",

          address: "شارع البحر الاعظم ",
          phone: "01210487781",
          fax: "088-2131662",
          imgSrc: require("@/assets/Images/35.png"),
          link: "https://www.google.com/maps/place/Al+Bahr+Al+Aazam,+Dayrout+El-Shareef,+Dairut,+Assiut+Governorate/@27.5556249,30.8162695,18z/data=!4m5!3m4!1s0x1444de125e605c41:0xd0ce70d5dee4beaf!8m2!3d27.5555179!4d30.8173799",
        },
        {
          Id: 11,
          name: "مركز خدمة عملاء فرع أبوتيج ",
          address: "بجوار رعاية الطفل",
          phone: "01210487708",
          fax: "088-2131662",
          imgSrc: require("@/assets/Images/33.jpg"),
          link:""
        },
        {
          Id: 12,
          name: "مركز خدمة عملاء فرع ساحل سليم",
          address: "شارع الجمهوريه  ",
          phone: "01210487718",
          fax: "088-2131662",
          imgSrc: require("@/assets/Images/32.png"),
          link:"https://www.google.com/maps/place/Holding+Co.+For+Water+%26+Wastewater+-+HCWW/@27.1917777,31.1867564,19.29z/data=!4m15!1m9!3m8!1s0x14450bf63c55c819:0xbd2fe63ce48274c9!2sEl+Watania+Palace+Hotel,+Assiut!5m2!4m1!1i2!8m2!3d27.191726!4d31.1870991!3m4!1s0x14450bf60fe0dae9:0x2e9381b21a721e12!8m2!3d27.1920546!4d31.1874705"
        },
        {
          Id: 2,
          name: "مركز خدمة عملاء فرع منفلوط",

          address: "شارع جسر ابومنديل - بجوار المعهد الأزهري ",
          phone: "01210487749",
          fax: "088-2131662",
          link:"https://www.google.com/maps/place/Gesr+Abou+Mandeil,+Manfalout,+Manfalut,+Assiut+Governorate/@27.3114511,30.9626962,17.71z/data=!4m5!3m4!1s0x14450315629c54e7:0x15af9a1974dbb4e6!8m2!3d27.3113461!4d30.9627363"
        },
        {
          Id: 3,
          name: "مركز خدمة عملاء فرع القوصيه ",

          address: "امام المرور القديم",
          phone: "01210487678",
          fax: "088-2131662",
          link:"https://www.google.com/maps/place/%D9%85%D9%86%D8%B7%D9%82%D9%87+%D8%B4%D8%B1%D9%83%D9%87+%D8%A7%D9%84%D9%85%D9%8A%D8%A7%D9%87%E2%80%AD/@27.4430064,30.8180709,18z/data=!4m12!1m6!3m5!1s0x1444e3e5bffd39e7:0xc6a36f596cc68c8c!2sQuseya+Traffic+Department!8m2!3d27.4428768!4d30.8196835!3m4!1s0x1444e35cd81fcae7:0x60bc11a6eb493961!8m2!3d27.4426002!4d30.8193818"
        },

        {
          Id: 7,
          name: "مركز خدمة عملاء فرع أبنوب  ",

          address: "شارع عثمان الغزالي بجوار المخبز الاَلي",
          phone: "01210487737",
          fax: "088-2131662",
        },
        {
          Id: 8,
          name: "مركز خدمة عملاء فرع مركز الفتح ",

          address: "الناصريه أمام مركز الشرطة",
          phone: "01210487740",
          fax: "088-2131662",
        },
        {
          Id: 9,
          name: "مركز خدمة عملاء فرع مركز البداري ",

          address: "شارع مجلس النيابه القديم",
          phone: "01284447689",
          fax: "088-2131662",
        },
        {
          Id: 10,
          name: "مركز خدمة عملاء فرع الغنايم ",
          address: "الغنايم بحري خلف مركز الشرطة  ",
          phone: "01210487696",
          fax: "088-2131662",
        },

        {
          Id: 12,
          name: "مركز خدمة عملاء فرع صدفه ",

          address: "شارع الصيانة",
          phone: "01210487698",
          fax: "088-2131662",
        },
      ],
    };
  },
};
</script>
<style >
h3 {
  font-family: Arial, Helvetica, sans-serif;
}
.changeTitleStyle {
  background: rgba(222, 184, 135, 0.192) !important;
}
.imgService {
  box-shadow: 10px 10px 10px gray;
}
.branchName {
  font-weight: bold;
}
</style>